* {
  box-sizing: border-box;
}

fieldset {
  border: none !important;
}

:focus-visible {
  outline: none !important;
}

* {
  font-family: "Noto Serif", serif;
}

.dnone {
  display: none;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: normal !important;
  color: #3f4444 !important;
  box-sizing: border-box;
  background-color: #f7f7f7 !important;
  font-family: "Noto Serif", serif !important;
}

.fontWeight700 {
  font-weight: 700;
  color: #3f4444;
}

.oldTotal {
  color: #3f4444bf;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 15px;
  padding-right: 10px;
  font-family: "Noto Serif", serif;
}

.paddingTop {
  padding-top: 75px;
}

.css-1vovazs {
  font-family: "Noto Serif", serif !important;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  color: #3f4444 !important;
  display: inline-block;
  font-size: 14px;
  font-family: "Noto Serif", serif;
  font-weight: 700;
  line-height: 18px;
  transition: 0.5s ease;
  text-decoration-color: #3f4444 !important;
}

a:hover {
  color: #1976d2 !important;
  transition: 0.5s ease;
  text-decoration-color: #1976d2 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bebas Neue", serif;
  color: #3f4444;
}

h2 {
  font-size: 40px;
  font-weight: 400;
  line-height: 40px;
}

h3 {
  margin: 0 0 15px 0;
  font-size: 30px;
  font-weight: 400;
  line-height: 30px;
}

h4 {
  margin: 0 0 15px 0;
  font-size: 26px;
  font-weight: 400;
  line-height: 26px;
}

h5 {
  margin: 0 0 15px 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
}

h6 {
  margin: 0 0 15px 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
}

p {
  color: #3f4444bf;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 15px;
  font-family: "Noto Serif", serif;
  /* text-transform: capitalize; */
}

small {
  color: #3f4444bf;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0 0 15px;
  font-family: "Noto Serif", serif;
}

button{
  transform: inherit !important;
  transition: inherit !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

ol li::marker {
  font-family: '"Bebas Neue", serif' !important;
}

/* Form Css */
form {
  margin: 0 !important;
}

.MuiFormLabel-root {
  color: #3f4444 !important;
  margin: 0px 0px 10px 0px;
  display: flex;
  font-size: 16px !important;
  font-family: "Noto Serif", serif !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  text-transform: capitalize;
}

input {
  color: #3f4444;
  width: 100%;
  border: 1px solid #f1eedb;
  height: 50px;
  outline: none;
  padding: 12px 20px;
  font-size: 16px;
  background: #fff !important;
  transition: 0.5s ease;
  font-family: "Noto Serif", serif;
  font-weight: 400;
  line-height: 24px;
  border-radius: 30px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  width: 100%;
  border: 1px solid #f1eedb;
  outline: none;
  padding: 12px 20px;
  font-size: 16px;
  background: #fff;
  color: #3f4444;
  box-sizing: border-box;
  transition: 0.5s ease;
  font-family: "Noto Serif", serif;
  font-weight: 400;
  line-height: 24px;
  border-radius: 10px;
  resize: none;
}

input::placeholder,
textarea::placeholder {
  color: #3f444480;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  /* text-transform: lowercase; */
}

.ElementsApp .InputElement.is-invalid {
  color: #3f4444 !important;
}

/* input::placeholder::first-letter,
textarea::placeholder::first-letter{
  text-transform: uppercase;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: 0.5s ease;
}

input:hover,
textarea:hover,
input:focus,
textarea:focus,
input:active,
textarea:active {
  border: 1px solid rgba(63, 68, 68, 0.12);
  transition: 0.5s ease;
}

input:disabled,
textarea:disabled,
button:disabled,
button[disabled] {
  background: rgba(63, 68, 68, 0.03);
}

/* select box */
body .MuiSelect-select {
  border-radius: 32px !important;
  width: 100% !important;
  display: table !important;
  background: #fff !important;
  border: 1px solid #f1eedb !important;
  padding: 14px 20px !important;
  color: #3f4444 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  min-height: 40px !important;
  position: relative !important;
  transition: 0.5s ease;
  overflow: hidden !important;
  box-sizing: border-box !important;
  font-family: "Noto Serif", serif;
}

body .MuiSelect-select span {
  color: #3f444480;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

body .MuiSelect-select:hover,
body .MuiSelect-select:focus,
body .MuiSelect-select:active {
  /* border: 1px solid rgba(63, 68, 68, 0.12) !important; */
  transition: 0.5s ease;
}

.MuiOutlinedInput-root {
  width: 100%;
}

.MuiSelect-icon {
  display: none !important;
}

body .MuiSelect-select::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, -20%);
  border-left: 0;
  border-style: solid;
  margin: 0;
  border-width: 8px;
  border-radius: 4px;
  border-color: #3f4444;
  border-right-color: transparent;
  border-left-color: transparent;
  transition: 0.3s ease;
}

body .MuiSelect-select[aria-expanded="false"]::after {
  border-bottom-color: transparent;
}

body .MuiSelect-select[aria-expanded="true"]::after {
  border-top-color: transparent;
  transform: translate(-50%, -100%);
}

.MuiTableCell-head {
  color: #3f4444 !important;
  padding: 15px 12px !important;
  font-size: 18px !important;
  background: #f7f7f7 !important;
  font-family: "Bebas Neue", serif !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  border-color: #dddddd !important;
  border-bottom: none !important;
}

.MuiTableCell-body {
  color: #3f4444 !important;
  padding: 10px 12px !important;
  font-size: 14px !important;
  font-family: "Noto Serif", serif !important;
  font-weight: 400 !important;
  line-height: 14px !important;
  max-width: 250px !important;
  border-color: #dddddd !important;
}

#mouse-over-popover .MuiPopover-paper {
  display: inline-table !important;
  /* min-width: 200px !important;
  min-height: 100px !important; */
}

/* drop down  */

.MuiMenuItem-root {
  font-size: 14px !important;
  line-height: normal !important;
  min-height: 35px !important;
  font-family: "Noto Serif", serif !important;
  background-color: #fff !important;
  padding: 10px 15px !important;
  /* border-bottom: 1.5px solid #ddd !important; */
}

.MuiMenuItem-root:hover,
.MuiMenuItem-root:active {
  background-color: #f1eedb !important;
}

.MuiMenu-paper {
  max-height: 300px !important;
  width: auto;
  border-radius: 30px !important;
  overflow-x: hidden !important;
  box-shadow: none !important;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important; */
}

.MuiMenu-paper::-webkit-scrollbar {
  appearance: none;
  width: 0;
}

/* drop down */

.MuiToggleButton-root.Mui-selected {
  background: #ffc200 !important;
}

.MuiPopover-root {
  overflow-y: inherit !important;
}

.MuiAlert-icon {
  display: none !important;
}

.MuiAccordion-root {
  padding: 20px !important;
  border-radius: 10px !important;
}

/* date picker css */
.Mui-disabled.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
  background-color: transparent;
  color: #9ea1a1 !important;
}

body iframe {
  border: none !important;
  outline: none !important;
}

.MuiDialog-paper {
  border: 1px solid #e5e7eb;
  box-shadow: 0px 10px 15px -3px #1018281a !important;
  border-radius: 6px !important;
}

body .MuiPickersCalendarHeader-monthText,
body .MuiTypography-subtitle1.MuiTypography-alignCenter,
.PrivatePickersFadeTransitionGroup-root {
  color: #3f4444;
  font-family: "Bebas Neue", serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
}

body .MuiPickersDay-day,
body .MuiTypography-caption,
.PrivatePickersSlideTransition-root button {
  color: #3f4444 !important;
  font-family: "Noto Serif", serif;
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 24px;
  border: none !important;
}

.PrivatePickersFadeTransitionGroup-root {
  overflow: hidden !important;
}

.PrivatePickersSlideTransition-root::-webkit-scrollbar-thumb:horizontal,
.PrivatePickersFadeTransitionGroup-root::-webkit-scrollbar-thumb:horizontal {
  appearance: none;
  width: 0;
  height: 0;
}

.PrivatePickersSlideTransition-root::-webkit-scrollbar-thumb:vertical,
.PrivatePickersFadeTransitionGroup-root::-webkit-scrollbar-thumb:vertical {
  appearance: none;
  width: 0;
  height: 0;
}

.MuiDateRangePickerDay-rangeIntervalPreview {
  border: none !important;
}

.MuiCalendarPicker-root>div:nth-child(1) {
  padding: 10px 10px 30px;
  border-bottom: 1px solid #ddd;
  min-height: 60px;
}

.MuiCalendarPicker-root>div:nth-child(1) div {
  color: #3f4444;
  font-family: "Bebas Neue", serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
}

.MuiPickersSlideTransition-transitionContainer {
  /* border-top: 1px solid #ddd !important; */
  padding: 10px 0;
}

.MuiPickersCalendarHeader-switchHeader {
  border-bottom: 1px solid #ddd;
  padding: 15px 10px 35px !important;
}

body .MuiPickersDay-today {
  border: none !important;
  color: #f0730a !important;
  background-color: transparent;
}

.MuiDialogActions-spacing button {
  border-radius: 30px;
  box-shadow: 0 1px 2px rgb(16 24 40 / 5%);
  color: #3f4444 !important;
  font-family: "Bebas Neue", serif;
  font-size: 20px;
  line-height: 20px;
  padding: 10px;
  width: 50%;
}

body .MuiPickersArrowSwitcher-root button {
  display: block !important;
  padding: 0;
}

.MuiPickersDateRangeDay-rangeIntervalDayHighlight {
  background-color: rgb(255 194 0 / 60%) !important;
}

.PrivatePickersSlideTransition-root .MuiButtonBase-root.Mui-selected,
.MuiPickersDay-daySelected,
.MuiDialogActions-spacing button:last-child,
.PrivatePickersYear-yearButton.Mui-selected,
.MuiButtonBase-root .MuiPickersDay-root:hover,
.MuiButtonBase-root .MuiPickersDay-root:focus {
  background-color: #ffc200 !important;
}

.PrivatePickersYear-yearButton.Mui-selected {
  color: #3f4444 !important;
}

.MuiDialogActions-spacing button:first-child {
  background-color: #e3ddd2;
}

.PrivatePickersSlideTransition-root {
  min-height: 200px !important;
  overflow: hidden !important;
}

.MuiDialogActions-root.MuiDialogActions-spacing {
  border-top: 1px solid #dddddd;
  margin: 12px 0;
  padding: 20px 10px 0px;
}

.MuiDialogContent-root {
  padding: 0 5px !important;
  overflow-x: inherit;
}

.MuiDialogContent-root>div,
.MuiDialogContent-root>div>div {
  width: 100% !important;
}

.MuiDialogContent-root svg {
  color: #3f4444;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #ffc200 !important;
}

.MuiIconButton-edgeStart,
.MuiIconButton-edgeEnd {
  padding: 0;
}

.MuiAppBar-positionFixed {
  background-color: #e3ddd2 !important;
}

body .Calander-datapickerBox-30 fieldset {
  border-color: transparent !important;
}

.MuiBackdrop-root {
  background: rgba(0, 0, 0, 0.3) !important;
}

.toolbarTitle {
  color: #3f4444;
  font-family: Bebas Neue, serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
}

body .MuiAccordion-root {
  box-shadow: none;
}

.MuiAccordionSummary-root {
  min-height: auto !important;
}

.MuiAccordionSummary-root img,
.MuiAccordionSummary-root svg {
  height: 20px;
  width: 20px;
}

.MuiAccordionDetails-root {
  padding: 0 !important;
}

.MuiPaper-root::before {
  content: "";
  display: none !important;
}

.MuiTabs-root {
  min-height: 45px !important;
}

.MuiDrawer-paperAnchorRight {
  overflow-x: hidden;
}

/* loader css */
.loader-container.body {
  z-index: 9999 !important;
  background: rgba(0, 0, 0, 0.4) !important;
}

.loader.body {
  background-color: transparent !important;
}

.loader .spinner {
  border-top-color: #ffc107 !important;
}

.loader.box .message,
.loader.body .message {
  color: #ffc107 !important;
  font-weight: 700;
  font-size: 18px;
}

.MuiPickersModal-dialogRoot {
  border: none !important;
  outline: none !important;
}

.MuiAccordionSummary-expandIconWrapper img,
.MuiAccordionSummary-expandIconWrapper svg {
  display: none;
}

.MuiAccordionSummary-content::after {
  content: "";
  height: 18px;
  width: 18px;
  position: absolute;
  right: 0;
  background-image: url("../public/images/arrow-close.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.MuiAccordionSummary-content.Mui-expanded::after {
  content: "";
  background-image: url("../public/images/arrow-open.svg");
}

/* Multi date picker */
.rmdp-header-values {
  margin: 0 10px !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #ffc200 !important;
  box-shadow: none !important;
  color: #3f4444 !important;
}

.rmdp-day.rmdp-disabled span:hover,
.rmdp-day-hidden span:hover {
  background-color: transparent !important;
  color: #9ea1a1 !important;
}

.rmdp-day span:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
  color: #3f4444 !important;
}

.rmdp-day,
.rmdp-week-day {
  height: 36px !important;
  width: 36px !important;
}

.rmdp-header-values span {
  color: #3f4444 !important;
  font-size: 28px !important;
  line-height: 36px;
  font-weight: 400 !important;
  font-family: "Bebas Neue", serif;
}

.rmdp-week-day,
.rmdp-header-values {
  color: #3f4444 !important;
  font-size: 16px !important;
  line-height: 24px;
  font-weight: 400 !important;
}

.rmdp-arrow-container .rmdp-arrow {
  font-size: 20px !important;
  width: 8px !important;
  height: 8px !important;
  border-color: #3f4444 !important;
}

.rmdp-day.rmdp-today span {
  background-color: #fff;
  color: #f0730a !important;
}

.rmdp-arrow-container {
  padding: 6px !important;
  height: 30px !important;
  width: 30px !important;
  line-height: 15px;
  display: block !important;
}

.rmdp-arrow-container:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none !important;
}

.rmdp-day-picker>div:first-child {
  border-top: 1px solid #e5e7eb;
  padding: 10px 0 0;
}

.rmdp-calendar {
  height: max-content;
  padding: 12px 6px 0 !important;
}

.rmdp-left {
  left: auto !important;
  right: 32px !important;
}

.rmdp-day-picker {
  padding: 12px 10px !important;
}

.rmdp-day {
  margin: 3px;
}

.rmdp-day span {
  font-size: 16px !important;
  line-height: 24px;
}

.rmdp-shadow {
  box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1),
    0px 4px 6px -4px rgba(16, 24, 40, 0.1) !important;
  border-radius: 6px !important;
  border: 1px solid #e5e7eb;
}

.rmdp-ep-arrow[direction="top"] {
  display: none !important;
}

.rmdp-week-day {
  /* min-width: 38px; */
}

body #mouse-over-popover .MuiPopover-paper {
  overflow: inherit !important;
  min-width: 180px !important;
  max-width: 350px !important;
}

body .MuiPopover-paper::after {
  /* content: '';
  left: 10%;
  bottom: -10px;
  position: absolute;
  margin-left: -5px;
  border-color: #722525 transparent transparent transparent;
  border-style: solid;
  border-width: 5px;
  z-index: 99999; */
}

.req {
  font-size: 15px;
  /* color: #DF3F51; */
  color: #3f4444;
  padding: 0 0 0 3px;
  display: flex;
  align-items: center;
  align-content: center;
}

.PrivateSwipeArea-root {
  width: 0 !important;
}

.errorMessage {
  color: #df3f51;
  margin: 5px 0px 0 10px !important;
  display: block;
  font-size: 13px;
  font-family: "Noto Serif", serif;
  font-weight: 400;
  line-height: 16px;
  /* text-transform: lowercase; */
}

.errorMessage::first-letter {
  /* text-transform: capitalize; */
}

.MuiButtonBase-root:hover,
.MuiButtonBase-root:active {
  /* background-color: #fff !important; */
}

.MuiTooltip-tooltip {
  background-color: #fff !important;
  border-radius: 8px !important;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3) !important;
  color: #3f4444 !important;
  font-family: "Noto Serif", serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 14px !important;
  padding: 10px !important;
}

.MuiTooltip-arrow {
  color: #fff !important;
}

/* table */
table tr th {
  width: 33.33%;
  padding: 15px 20px;
  background: #e3ddd2;
  text-align: left;
  font-family: "Bebas Neue", serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 26px;
  vertical-align: top;
}

table tr td {
  color: #3f4444bf;
  border: none;
  padding: 15px 20px 0;
  font-size: 16px;
  line-height: 26px;
  text-align: left;
  vertical-align: top;
}

.time-slot li:hover {
  background-color: #f1eedb !important;
}

.lightgray {
  color: #3f444480 !important;
}

.spinner-container .loading-spinner {
  display: block !important;
}

/* date picker css end */

@media (max-width: 1200px) {
  h2 {
    font-size: 35px;
    line-height: 35px;
  }
}

@media (max-width: 640px) {

  .MuiFormLabel-root,
  input::placeholder,
  textarea::placeholder,
  body .MuiSelect-select {
    font-size: 14px !important;
  }

  input {
    border: 1px solid #fff;
    font-size: 14px !important;
  }

  body .MuiSelect-select {
    border: 1px solid #fff !important;
  }

  textarea {
    border: 1px solid transparent;
    font-size: 14px !important;
  }

  h2 {
    font-size: 35px;
    line-height: 35px;
  }

  h3 {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  h5 {
    font-size: 18px;
    line-height: 18px;
  }

  p {
    font-size: 14px;
    line-height: 19px;
  }

  small {
    font-size: 12px;
    line-height: normal;
  }

  .MuiAccordionSummary-content::after {
    content: "";
    height: 16px;
    width: 16px;
  }

  .rmdp-header-values span,
  .MuiCalendarPicker-root>div:nth-child(1) div {
    font-size: 25px !important;
  }

  body .MuiPickersDay-day,
  body .MuiTypography-caption,
  .PrivatePickersSlideTransition-root button,
  .rmdp-day span {
    font-size: 14px !important;
    line-height: 20px;
  }

  .rmdp-week-day {
    font-size: 14px !important;
  }

  body #mouse-over-popover .MuiPopover-paper {
    overflow: inherit !important;
    min-width: 120px !important;
    max-width: 250px !important;
  }

  table tr th {
    padding: 10px 15px;
    font-size: 20px;
    line-height: 26px;
  }

  table tr td {
    padding: 10px 15px;
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 475px) {
  body #mouse-over-popover .MuiPopover-paper {
    overflow: auto !important;
    min-width: auto !important;
    max-width: auto !important;
  }
}

@media (max-width: 420px) {
  .errorMessage {
    font-size: 12px;
    margin-right: 0 !important;
  }

  h2 {
    font-size: 25px;
    line-height: 25px;
  }

  h3 {
    font-size: 25px;
    line-height: 25px;
  }

  h4 {
    font-size: 20px;
    line-height: 20px;
  }

  h5 {
    font-size: 16px;
    line-height: 16px;
  }
}